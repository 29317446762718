var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"addBladeName":"batch","archiveBladeName":"batch-archives","bladesData":_vm.bladesData,"bladeName":"batches","canAdd":"","canDelete":"","canExportCSV":"","canSearchLocal":"","flexColumn":"","getNewBladeData":function (bladeData) { return { locationID: bladeData.data != null ? bladeData.data.locationID : null } },"getParams":function (bladeData) { return { includeDetails: false, locationID: bladeData.data.id }},"headers":[
        { text: 'Product', value: 'product.productName', csv: 'product.abbreviation', title: 1, searchable: true },
        { text: 'Batchcode', value: 'batchcode', csv: true, subtitle: 1, searchable: true },
        { text: 'DOM', value: 'dom', textFilter: 'toShortDate', csv: true, subtitle: 2, searchable: true, prefix: 'DOM: ' },
        { text: 'EXP', value: 'exp', textFilter: 'toShortDate', csv: true },
        { text: 'Quantity', value: 'quantity', textFilter: 'toDisplayNumber', breakdown: true, isIcon: true, csv: true },
        { text: 'Status', value: 'status', display: true, subtitle: 3 }],"navigation":"batches","searchProps":['product.abbreviation'],"title":"Batches","useServerPagination":"","onFilter":_vm.sortBatches},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('BT-Snack',{model:{value:(_vm.msg),callback:function ($$v) {_vm.msg=$$v},expression:"msg"}})]},proxy:true},{key:"itemActions",fn:function(ref){
        var item = ref.item;
return [_c('BT-Btn',{attrs:{"bladeName":"batch-tracking","id":item.id,"leftIcon":"mdi-radar","small":"","title":"Batch tracking"}})]}},{key:"status",fn:function(ref){
        var item = ref.item;
return [(item.isPending)?_c('span',[_vm._v("Pending")]):(item.onHold)?_c('span',[_vm._v("On Hold")]):_c('span',[_vm._v("Released")])]}},{key:"quantity",fn:function(ref){
        var item = ref.item;
return [(item.isPending)?_c('span',[_vm._v("("+_vm._s(_vm._f("toDisplayNumber")(item.quantityGoal))+")")]):_c('span',[_vm._v(_vm._s(_vm._f("toDisplayNumber")(item.quantity)))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }