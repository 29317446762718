<template>
    <BT-Blade-Items
        addBladeName="batch"
        archiveBladeName="batch-archives"
        :bladesData="bladesData"
        bladeName="batches"
        canAdd
        canDelete
        canExportCSV
        canSearchLocal
        flexColumn
        :getNewBladeData="bladeData => { return { locationID: bladeData.data != null ? bladeData.data.locationID : null } }"
        :getParams="bladeData => { return { includeDetails: false, locationID: bladeData.data.id }}"
        :headers="[
            { text: 'Product', value: 'product.productName', csv: 'product.abbreviation', title: 1, searchable: true },
            { text: 'Batchcode', value: 'batchcode', csv: true, subtitle: 1, searchable: true },
            { text: 'DOM', value: 'dom', textFilter: 'toShortDate', csv: true, subtitle: 2, searchable: true, prefix: 'DOM: ' },
            { text: 'EXP', value: 'exp', textFilter: 'toShortDate', csv: true },
            { text: 'Quantity', value: 'quantity', textFilter: 'toDisplayNumber', breakdown: true, isIcon: true, csv: true },
            { text: 'Status', value: 'status', display: true, subtitle: 3 }]"
        navigation="batches"
        :searchProps="['product.abbreviation']"
        title="Batches"
        useServerPagination
        :onFilter="sortBatches">
        <template v-slot:actions>
            <BT-Snack v-model="msg" />
        </template>
        <template v-slot:itemActions="{ item }">
            <BT-Btn
                bladeName="batch-tracking"
                :id="item.id"
                leftIcon="mdi-radar"
                small
                title="Batch tracking" />
        </template>
        <template v-slot:status="{ item }">
            <span v-if="item.isPending">Pending</span>
            <span v-else-if="item.onHold">On Hold</span>
            <span v-else>Released</span>
        </template>
        <template v-slot:quantity="{ item }">
            <span v-if="item.isPending">({{ item.quantityGoal | toDisplayNumber }})</span>
            <span v-else>{{ item.quantity | toDisplayNumber }}</span>
        </template>
    </BT-Blade-Items>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Batches-Blade',
    data() {
        return {
            loadingMsg: null,
            msg: null,
            mappings: [],
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        createBatch(qty, exp, abb, products, location) {
            console.log('creating batch: ' + abb);
            var productID = null;
            var e = products.find(z => z.abbreviation == abb);
            if (e != null) {
                productID = e.id;
            }
            else {
                var m = this.mappings.find(z => z.externalValue == abb);
                if (m != null) {
                    productID = m.itemID;
                }
            }
            var res = {
                quantity: qty, 
                productID: productID,
                dom: this.$BlitzIt.auth.createUTC(null, -1),
                exp: this.$BlitzIt.auth.formTZtoUTC(exp),
                locationID: location.id
            };
            return res;
        },
        sortBatches(list) {
            return list.sort(
                firstBy(x => x.product != null ? x.product.sortNumber : 0 )
                .thenBy(x => x.dom )
            )
            //return list.orderBy('product.sortNumber');
            //return list.sort((a, b) => { return a.product.sortNumber > b.product.sortNumber || a.dom > b.dom });
            //return list;
        }
    }
}
</script>